import React, { useState, useEffect } from 'react';
import "./lhf.css";
import NavBar from "../../../components/navbar";
//import vote from "../../../images/bestweb-24-vote4us.webp"; //  BestWeb - logo
import TopWeb from "../../../images/TopWeb-May-2024.jpg"; //  TopWeb - logo
import Footer3CS from "../../../components/footerbar";
import { Helmet } from "react-helmet";

export default function DedicatedHealthCareInitiatives() {

  useEffect(() => { window.scrollTo(0, 0); }, []);

  const Slideshow = ({ slides, autoplay = true, autoplayInterval = 3000 }) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
      if (autoplay) {
        const interval = setInterval(() => {
          setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
        }, autoplayInterval);

        return () => clearInterval(interval);
      }
    }, [autoplay, autoplayInterval, slides.length]);

    const goToPrevSlide = () => {
      setCurrentSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length);
    };

    const goToNextSlide = () => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    };

    return (
      <div className="slideshow-container">
        <div className="controls">
          <button className="prev-button" onClick={goToPrevSlide}>
            &lt;
          </button>

          <div className="progress-bar">
            <div
              className="progress"
              style={{
                width: `${((currentSlide + 1) / slides.length) * 100}%`,
              }}
            ></div>
          </div>

          <button className="next-button" onClick={goToNextSlide}>
            &gt;
          </button>
        </div>

        <div className="slide">
          <img src={slides[currentSlide].imageUrl} alt={slides[currentSlide].caption} />
        </div>
      </div>
    );
  };

  const slides = [
    {
      imageUrl:
        "https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/sample-images/foundation/post-01/1.jpg",
      caption: "Slide 1",
    },
    {
      imageUrl:
        "https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/sample-images/foundation/post-01/2.jpg",
      caption: "Slide 2",
    },
    {
      imageUrl:
        "https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/sample-images/foundation/post-01/3.jpg",
      caption: "Slide 3",
    },
  ];

  return (
    <>
      <div style={{ backgroundColor: "#1f1f1f" }}>
        <style>
          {`
            /* Customize scrollbar */
            ::-webkit-scrollbar {
              width: 3px;
              background-color: #1f1f1f;
            }

            ::-webkit-scrollbar-thumb {
              background-color: #0056b3;
              background-clip: content-box;
            }

            ::-webkit-scrollbar-thumb:hover {
              background-color: #0056b3;
            }

            ::-webkit-scrollbar-track {
              background-color: #1f1f1f;
            }
          `}
        </style>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Dedicated Healthcare Initiatives | Lalan Group Sri Lanka
          </title>
          <meta
            name="title"
            content="Dedicated Healthcare Initiatives | Lalan Group Sri Lanka"
          />
          <meta
            name="description"
            content="Our healthcare initiatives include provision of health and dietary supplements, and reading glasses following periodic eye testing. "
          />
          <meta name="keywords" content="Dedicated Healthcare Initiatives" />
          <link
            rel="canonical"
            href="https://www.lalangroup.com/sustainability/lalith-hapangama-foundation/dedicated-health-care-initiatives/"
          />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="article" />
          <meta
            property="og:title"
            content="Dedicated Health Care Initiatives - Diversified Conglomerates in Sri Lanka | Lalan Group"
          />
          <meta
            property="og:url"
            content="https://www.lalangroup.com/sustainability/lalith-hapangama-foundation/dedicated-health-care-initiatives/"
          />
          <meta
            property="og:site_name"
            content="Diversified Conglomerates in Sri Lanka | Lalan Group"
          />
        </Helmet>
        <NavBar />
        <br />
        <div className="sub-icon">
          <a href="javascript:history.back()">
            <img
              src="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/serverless/back.png"
              alt="back"
              style={{
                borderRadius: "50px",
                textAlign: "left",
                marginRight: "100px",
                color: "white",
                height: "50px",
                position: "absolute",
                marginTop: "40px",
                marginLeft: "40px",
              }}
            />
          </a>
        </div>
        <div className="sub-para">
          <p className="p-size-1">Dedicated Health Care Initiatives</p>
          <p className="p-size-2">
            An estate medical officer has been engaged to overlook the minor
            health care needs of the workers, in addition to prescribing drugs
            for minor ailments. Special projects for the provision of health and
            dietary supplements, and reading glasses following periodic eye
            testing, are also ongoing and active.
          </p>
        </div>
        <br /> <br />
        <br /> <br />
        <div style={{ marginLeft: "100px", marginRight: "100px" }}>
          <Slideshow slides={slides} autoplay={true} autoplayInterval={3000} />
        </div>
        {/* BestWeb - start */}
        <div className="bestweb">
          <div style={{ display: "flex", flexDirection: "row" }}>
            {/* BestWeb - start */}
            {/* <div>
              <a
                href="https://www.vote.bestweb.lk/site/www_lalangroup_lk"
                target="_blank"
              >
                <img className="logopic" src={vote} alt="logo" />
              </a>
            </div> */}
            {/* BestWeb - end */}
            {/* TopWeb - start */}
            <div>
              <a href="https://topweb.lk/may2024/lalangroup" target="_blank">
                <img
                  style={{ marginLeft: "30px" }}
                  className="logopic-2"
                  src={TopWeb}
                  alt="logo"
                />
              </a>
            </div>
            {/* TopWeb - end */}
          </div>
        </div>
        <br /> <br />
        {/* BestWeb - end */}
        <Footer3CS />
      </div>
    </>
  );
}
